<template>
    <div class="row child-component">
        <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">
            <div class="d-block row col-lg-8 mx-auto">
                <div class="fieldborder" style="margin-top: 12px;">
                    <label>Check one of the following and list all that apply to the relationship between you and the
                        vendor. </label>
                    <OmniSelect style="position: sticky;margin-left: 11px;" placeholder="Select Relationship" name="Media"
                        :showLabel="false" label="Select Media" :options="RelationshipVendors"
                        v-model="ProdExpVendor.EmployeeVendorRelationship" />
                </div>
                <div class="fieldborder px-2" v-for="VendorRelation in ProdExpVendor.EmployeeVendorRelationship" :key="VendorRelation">
                    <span v-if="!IsNoneSelected">
                        <label>{{ GetRelationshipLabel(VendorRelation) }}</label>
                        <b-form-input autocomplete="off" class="omniipclass"
                            v-model="ProdExpVendor.EmployeeVendorRelationshipDetails[VendorRelation]"
                            :placeholder="GetRelationshipLabel(VendorRelation)" :maxlength="100"></b-form-input>
                    </span>
                </div>
                <div class="fieldborder" style="margin-top: 12px;">
                    <b-form-group
                        label="Has any form of compensation or gifts of any type been offered to you or any person related to you in exchange for placing an order with this vendor?"
                        class="timeonly-text">
                        <b-form-radio-group style="margin-left:14px;" v-model="ProdExpVendor.Compensation" id="time-only" name="time-only">
                            <b-form-radio value="YES">Yes</b-form-radio>
                            <b-form-radio value="NO">No</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </div>

                <label v-if="ProdExpVendor.Compensation == 'YES'">Compensation Dollars</label>
                <div class="fieldborder" style="margin-top: -33px;" v-if="ProdExpVendor.Compensation == 'YES'">
                    <div class="fieldborder" style="margin-top:30px;margin-left: 14px;">
                        <b-form-input autocomplete="off" id="compensation-dollar" class="form-control col-md-3"
                            placeholder="Compensation" v-model="ProdExpVendor.CompensationDollers" :maxLength="6"
                            @blur="formatDollars()"></b-form-input>
                        <div v-show="!IsCompensationDollarsValid" class="error-messages">
                            This field can only contain numerical values
                        </div>

                    </div>
                </div>

                <div class="fieldborder" style="margin-top: 10px;">
                    <b-form-checkbox id="checkbox-1" v-model="ProdExpVendor.Accept" value="true" name="checkbox-1"
                        style="padding-left:38px;">
                        <span style="font-weight:normal;text-transform:none"> By checking this box, the requestor is
                            confirming that he/she/they has conducted all acceptable measures of due diligence to ensure the
                            propriety of the vendor and confirm.</span>
                    </b-form-checkbox>
                </div>

            </div>
        </div>
        <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
            <button class="btn btn-link text-uppercase" @click="Cancel()">
                CANCEL
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Previous()">
                BACK
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Next()" :disabled="this.$v.$invalid || !IsFormAccepted">
                NEXT
            </button>
        </div>
    </div>
</template>


<style scoped>
.error-messages {
    font-size: 10px;
    color: red !important;
    font-family: "Open Sans";
    padding-left: 7px;
    padding-top: 5px;
}

label {
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8 !important;
    letter-spacing: normal;
    margin-bottom: 6px;
    color: #4d5161;
    text-transform: uppercase;
}

.floating-label-input {
    margin-top: 0px !important;
}

.fieldborder {
    padding-bottom: 7px !important;
}

.omniipclass {
    /* width: 415px !important; */
    height: 35px !important;
    border: #e9eef0 !important;
    /* style="width:415px; height:35px;" */
}

.Text {
    width: 250px;
    height: 196px;
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.Text .text-style-1 {
    font-weight: 600;
}
</style>
<script>
import {
    mapGetters
} from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
    validations: {
        ProdExpVendor: {

            Compensation: { required },
            Accept: { required },
            CompensationDollers: {
                required: requiredIf(function (model) {
                    return model.Compensation == "YES";
                })
            },
            EmployeeVendorRelationshipDetails: {
                FormerEmployeeName: {
                    required: requiredIf(function () {
                        return this.ProdExpVendor.EmployeeVendorRelationship.includes('FormerEmployeeName');
                    })
                },
                FamilyMemberName: {
                    required: requiredIf(function () {
                        return this.ProdExpVendor.EmployeeVendorRelationship.includes('FamilyMemberName')
                    })
                },
                FriendName: {
                    required: requiredIf(function () {
                        return this.ProdExpVendor.EmployeeVendorRelationship.includes('FriendName')
                    })
                },
                VendorRelationshipWithAgencyClient: {
                    required: requiredIf(function () {
                        return this.ProdExpVendor.EmployeeVendorRelationship.includes('VendorRelationshipWithAgencyClient')
                    })
                },
                VendorOfFormerEmployee: {
                    required: requiredIf(function () {
                        return this.ProdExpVendor.EmployeeVendorRelationship.includes('VendorOfFormerEmployee')
                    })
                },
                VendorWasFormerEmployeeOfAgency: {
                    required: requiredIf(function () {
                        return this.ProdExpVendor.EmployeeVendorRelationship.includes('VendorWasFormerEmployeeOfAgency')
                    })
                },
                VendorHasRelationshipWithAnotherAgencyEmployee: {
                    required: requiredIf(function () {
                        return this.ProdExpVendor.EmployeeVendorRelationship.includes('VendorHasRelationshipWithAnotherAgencyEmployee')
                    })
                },
                Other: {
                    required: requiredIf(function () {
                        return this.ProdExpVendor.EmployeeVendorRelationship.includes('Other')
                    })
                }
            },

        }
    },
    data() {
        return {
            IsCompensationDollarsValid: true,
            AdditionalApprovals: [
                {
                    "label": "Accounting Approval",
                    value: "Accounting Approval"
                },
                {
                    "label": "CEO Approval",
                    value: "CEO Approval"
                },
                {
                    "label": "CFO Approval",
                    value: "CFO Approval"
                },
            ],

            RelationshipVendors: [{
                "label": "NONE",
                value: "NONE"
            },
            {
                "label": "Former Employee's Name",
                value: "FormerEmployeeName"
            },
            {
                "label": "Family Member's Name",
                value: "FamilyMemberName"
            },
            {
                "label": "Friend (name)",
                value: "FriendName"
            },
            {
                "label": "Vendor has a relationship w/ Agency Client",
                value: "VendorRelationshipWithAgencyClient"
            },
            {
                "label": "Former Employee’s Name",
                value: "VendorOfFormerEmployee"
            },
            {
                "label": "Former employee’s name of Agency",
                value: "VendorWasFormerEmployeeOfAgency"
            },
            {
                "label": "Name of employee with another Agency",
                value: "VendorHasRelationshipWithAnotherAgencyEmployee"
            },
            {
                "label": "Other",
                value: "Other"
            }
            ]

        }
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    mounted() {
        this.formatDollars();
    },
    computed: {
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        IsNoneSelected() {
            return this.ProdExpVendor.EmployeeVendorRelationship.length == 1 && this.ProdExpVendor.EmployeeVendorRelationship.findIndex(val => val == "NONE") > -1;
        },
        IsFormAccepted() {
            return this.ProdExpVendor.Accept == true || this.ProdExpVendor.Accept == 'true';
        },
        ...mapGetters('prodexpvendor', {
            ProdExpVendor: 'ProdExpVendor',
        }),
    },

    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    },
    methods: {
        Cancel() {
            this.$router.push("/VendorRequests");
        },
        formatDollars() {
            this.IsCompensationDollarsValid = true;
            if (this.ProdExpVendor.CompensationDollers && this.ProdExpVendor.CompensationDollers != '') {
                var num = this.ProdExpVendor.CompensationDollers.toString().replace(',', '');

                num = Number(num);
                if (!isNaN(num)) {
                    let temp = num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    this.ProdExpVendor.CompensationDollers = temp;
                }
                else {
                    this.IsCompensationDollarsValid = false;
                    this.ProdExpVendor.CompensationDollers = '';
                }
            }
        },
        Next() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
            if (this.id) {
                this.$router.push({
                    name: "Production Expense Vendor Review",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Production Expense Vendor Review",
                });
            }
        },
        Previous() {
            if (this.id) {
                this.$router.push({
                    name: "Production Expense Vendor Services",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Production Expense Vendor Services"
                });
            }
        },
        GetRelationshipLabel(value) {
            let relationsshipIndex = this.RelationshipVendors.findIndex(val => {
                return val.value == value;
            })
            if (relationsshipIndex > -1) {

                if (value == "VendorRelationshipWithAgencyClient") {
                    return "Explain the nature of the relationship";
                }
                else {
                    return this.RelationshipVendors[relationsshipIndex].label;
                }

            } else {
                return "";
            }
        },
    },

    watch: {
        "ProdExpVendor.EmployeeVendorRelationship": function (val) {
            var result = val;
            if (result.includes('NONE') && result.length > 1) {
                var index5 = result.indexOf('NONE');
                if (index5 > -1) {
                    if (index5 == 0) {
                        result.splice(index5, 1);
                    } else {
                        let arraLength = result.length;
                        for (var i = 0; i < arraLength; i++) {
                            if (i !== index5) {
                                result = val.splice(i, 1);
                            }
                        }
                    }

                }
            }
            val = result;
        },
        "ProdExpVendor.Compensation" : function (val) {
            if(val == 'NO') {
                this.ProdExpVendor.CompensationDollers = null;
            }
        }

    },
}
</script>
<style>
input#compensation-dollar {
    background-image: url("data:image/svg+xml;utf8,<svg \
                                xmlns='http://www.w3.org/2000/svg' \
                                             version='1.1' height='40px' width='40px'>\
                                     <text x='2' y='22' \
                                fill='gray' font-size='15'>$</text></svg>");
    background-repeat: no-repeat;

}
</style>
